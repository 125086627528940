exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-2-js": () => import("./../../../src/pages/index2.js" /* webpackChunkName: "component---src-pages-index-2-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-swap-terms-js": () => import("./../../../src/pages/swap-terms.js" /* webpackChunkName: "component---src-pages-swap-terms-js" */),
  "component---src-templates-auctions-home-js": () => import("./../../../src/templates/AuctionsHome.js" /* webpackChunkName: "component---src-templates-auctions-home-js" */),
  "component---src-templates-auctions-js": () => import("./../../../src/templates/Auctions.js" /* webpackChunkName: "component---src-templates-auctions-js" */),
  "component---src-templates-coins-leaderboard-js": () => import("./../../../src/templates/CoinsLeaderboard.js" /* webpackChunkName: "component---src-templates-coins-leaderboard-js" */),
  "component---src-templates-deal-details-js": () => import("./../../../src/templates/DealDetails.js" /* webpackChunkName: "component---src-templates-deal-details-js" */),
  "component---src-templates-deals-home-js": () => import("./../../../src/templates/DealsHome.js" /* webpackChunkName: "component---src-templates-deals-home-js" */),
  "component---src-templates-friend-tech-leaderboard-js": () => import("./../../../src/templates/FriendTechLeaderboard.js" /* webpackChunkName: "component---src-templates-friend-tech-leaderboard-js" */),
  "component---src-templates-giveaways-home-js": () => import("./../../../src/templates/GiveawaysHome.js" /* webpackChunkName: "component---src-templates-giveaways-home-js" */),
  "component---src-templates-giveaways-js": () => import("./../../../src/templates/Giveaways.js" /* webpackChunkName: "component---src-templates-giveaways-js" */),
  "component---src-templates-learn-details-js": () => import("./../../../src/templates/LearnDetails.js" /* webpackChunkName: "component---src-templates-learn-details-js" */),
  "component---src-templates-learn-tag-details-js": () => import("./../../../src/templates/LearnTagDetails.js" /* webpackChunkName: "component---src-templates-learn-tag-details-js" */),
  "component---src-templates-learns-home-js": () => import("./../../../src/templates/LearnsHome.js" /* webpackChunkName: "component---src-templates-learns-home-js" */),
  "component---src-templates-market-cap-js": () => import("./../../../src/templates/MarketCap.js" /* webpackChunkName: "component---src-templates-market-cap-js" */),
  "component---src-templates-partner-landing-js": () => import("./../../../src/templates/PartnerLanding.js" /* webpackChunkName: "component---src-templates-partner-landing-js" */),
  "component---src-templates-portfolio-tracker-js": () => import("./../../../src/templates/PortfolioTracker.js" /* webpackChunkName: "component---src-templates-portfolio-tracker-js" */),
  "component---src-templates-swap-js": () => import("./../../../src/templates/Swap.js" /* webpackChunkName: "component---src-templates-swap-js" */)
}

